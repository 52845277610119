<template>
  <div class="card card-primary">
    <div class="card-header" :class="bgColor">
      <h3 class="card-title">
        <span class="info-box-icon"><i :class="fontClass"></i></span>
        <span class="info-box-text pl-3">{{ title }}</span>
      </h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <dl class="row mb-0">
            <dt class="col-sm-8">Total</dt>
            <dd class="col-sm-4 text-right">{{ value.total }}</dd>
            <dt class="col-sm-8">New</dt>
            <dd class="col-sm-4 text-right">{{ value.totalNew }}</dd>
            <dt class="col-sm-8">Completed</dt>
            <dd class="col-sm-4 text-right">{{ value.totalCompleted }}</dd>
          </dl>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <dl class="row mb-0">
            <dt class="col-sm-8">MIR</dt>
            <dd class="col-sm-4 text-right">{{ value.totalMIR }}</dd>
            <dt class="col-sm-8">WFR</dt>
            <dd class="col-sm-4 text-right">{{ value.totalWFR }}</dd>
            <dt class="col-sm-8">Canceled</dt>
            <dd class="col-sm-4 text-right">{{ value.totalCanceled }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  name: "InfoBoxCom",
  props: ["title", "value", "bgColor", "fontClass"],
  data() {
    return {};
  },
};
</script>
