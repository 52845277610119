<template>
  <div class="p-5">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="row" v-if="gettDashboardComponentDataObj">
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Draft Police check'"
              :value="gettDashboardComponentDataObj.totalDraftPC"
              :bgColor="'bg-secondary text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'New Police check'"
              :value="gettDashboardComponentDataObj.totalNewPC"
              :bgColor="'bg-primary text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'More Information Required'"
              :value="gettDashboardComponentDataObj.totalMIRPC"
              :bgColor="'bg-warning text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Waiting for Police Check Review'"
              :value="gettDashboardComponentDataObj.totalWFPRPC"
              :bgColor="'bg-success text-white'"
              :fontClass="'fas fa-flag-checkered '"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Completed'"
              :value="gettDashboardComponentDataObj.totalCompletedPC"
              :bgColor="'bg-info text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxCom
              :title="'Canceled'"
              :value="gettDashboardComponentDataObj.totalCanceledPC"
              :bgColor="'bg-black text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxCom>
          </div>
        </div>
      </div>

      <!-- new section -->
      <div class="col-lg-12 col-md-12 col-12">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Projects'"
              :value="getProjectsDashboardData"
              :bgColor="'bg-secondary text-white'"
              :fontClass="'fas fa-project-diagram'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Police checks'"
              :value="getPoliceCheckDashboardData"
              :bgColor="'bg-primary text-white'"
              :fontClass="'fas fa-flag-checkered'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Right to work in Australia check'"
              :value="getRightWorkAustraliaDashboardData"
              :bgColor="'bg-warning text-white'"
              :fontClass="'fas fa-globe-asia'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Academic qualification check'"
              :value="getAcademicQualificationDashboardData"
              :bgColor="'bg-success text-white'"
              :fontClass="'fas fa-user-graduate'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Professional membership check'"
              :value="getProfessionalMembershipDashboardData"
              :bgColor="'bg-info text-white'"
              :fontClass="'fas fa-user-tie'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Employment history check'"
              :value="getEmploymentHistoryDashboardData"
              :bgColor="'bg-dark text-white'"
              :fontClass="'fas fa-people-carry'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Bankruptcy record check'"
              :value="getBankruptcyRecordDashboardData"
              :bgColor="'bg-secondary text-white'"
              :fontClass="'fas fa-file-invoice-dollar'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Drivers licence and traffic offence check'"
              :value="getDriversOffencesDashboardData"
              :bgColor="'bg-primary text-white'"
              :fontClass="'fas fa-money-check'"
            >
            </WDInfoBoxTableCom>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <WDInfoBoxTableCom
              :title="'Reference check'"
              :value="getReferenceDashboardData"
              :bgColor="'bg-warning text-white'"
              :fontClass="'fas fa-crop-alt'"
            >
            </WDInfoBoxTableCom>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import WDInfoBoxCom from "../../components/wd/WDInfoBoxCom.vue";
import WDInfoBoxTableCom from "../../components/wd/WDInfoBoxTableCom.vue";
export default {
  components: {
    WDInfoBoxCom,
    WDInfoBoxTableCom,
  },
  data: function () {
    return {
      loginUserObj: {},
    };
  },
  computed: {
    ...mapGetters([
      "gettDashboardData",
      "getProjectsDashboardData",
      "gettDashboardComponentDataObj",
      "getRightWorkAustraliaDashboardData",
      "getAcademicQualificationDashboardData",
      "getProfessionalMembershipDashboardData",
      "getEmploymentHistoryDashboardData",
      "getBankruptcyRecordDashboardData",
      "getDriversOffencesDashboardData",
      "getPoliceCheckDashboardData",
      "getReferenceDashboardData",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchDashboardData",
      "fetchProjectsDashboardData",
      "fetchRightWorkAustraliaDashboardData",
      "fetchAcademicQualificationDashboardData",
      "fetchProfessionalMembershipDashboardData",
      "fetchEmploymentHistoryDashboardData",
      "fetchBankruptcyRecordDashboardData",
      "fetchDriversOffencesDashboardData",
      "fetchDriversOffencesDashboardData",
      "fetchPoliceCheckDashboardData",
      "fetchReferenceDashboardData",
    ]),
  },

  created() {
    // Progress bar
    this.$Progress.start();
    this.loginUserObj = authService.getUserFromToken();
    this.fetchDashboardData("");
    this.fetchProjectsDashboardData();
    this.fetchRightWorkAustraliaDashboardData();
    this.fetchAcademicQualificationDashboardData();
    this.fetchProfessionalMembershipDashboardData();
    this.fetchEmploymentHistoryDashboardData();
    this.fetchBankruptcyRecordDashboardData();
    this.fetchDriversOffencesDashboardData();
    this.fetchPoliceCheckDashboardData();
    this.fetchReferenceDashboardData();
  },
};
</script>
